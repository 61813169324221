import { Flex, FlexProps } from '@chakra-ui/react';

type Props = Omit<FlexProps, 'children'> & { children: React.ReactNode };

export const SvgBox = ({ children, ...rest }: Props): JSX.Element => (
  <Flex
    width="2rem"
    height="2rem"
    {...rest}
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </Flex>
);
