import { useMemo } from 'react';
import { Button as BaseButton } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';

type Variant = 'primary' | 'secondary' | 'tertiary' | 'danger';

type Props = Omit<ButtonProps, 'variant'> & {
  variant?: Variant;
};

type Styles = {
  [key in Variant]:
    | typeof primary
    | typeof secondary
    | typeof tertiary
    | typeof danger;
};

// 40% opacity for disabled buttons
const opacity = 0.4;

const primary = {
  color: 'white',
  backgroundColor: 'teal.400',
  _hover: { backgroundColor: 'teal.500' },
  _disabled: { opacity },
  _active: { backgroundColor: 'teal.600' },
};

const secondary = {
  color: 'teal.600',
  backgroundColor: 'white',
  _hover: { backgroundColor: 'teal.50' },
  _disabled: { opacity },
  _active: { backgroundColor: 'teal.100' },
};

const tertiary = {
  color: 'black',
  backgroundColor: 'white',
  _hover: { backgroundColor: `gray.50` },
  _disabled: { opacity },
  _active: { backgroundColor: `gray.100` },
};

const danger = {
  backgroundColor: `red.500`,
  color: 'white',
  _hover: { backgroundColor: `red.300` },
  _active: { backgroundColor: `red.600` },
  _disabled: { opacity },
};

const buttonStyles: Styles = {
  primary,
  secondary,
  tertiary,
  danger,
};

export const Button = ({
  children,
  variant = 'primary',
  ...rest
}: Props): JSX.Element => {
  const styles = useMemo(() => buttonStyles[variant], [variant]);
  return (
    <BaseButton shadow="sm" fontWeight="normal" {...rest} {...styles}>
      <span>{children}</span>
    </BaseButton>
  );
};

export type { Props as ButtonProps };
