import { forwardRef } from 'react';
import { Input } from '@chakra-ui/react';
import type { InputProps } from './InputTypes';
import { inputBaseStyle } from './inputBaseStyle';

export const EmailInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => (
    <Input
      ref={ref}
      {...props}
      type="email"
      width="100%"
      autoCapitalize="off"
      {...inputBaseStyle}
    />
  ),
);
