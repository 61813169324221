import { forwardRef } from 'react';
import { Select } from '@chakra-ui/react';
import type { SelectProps } from '@chakra-ui/react';
import { inputBaseStyle } from './inputBaseStyle';

export const SelectInput = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => (
    <Select {...props} ref={ref} width="100%" {...inputBaseStyle} />
  ),
);
