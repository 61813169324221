import { Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import type { WithChildren } from '../types';

type Props = WithChildren<Omit<FlexProps, 'as'>>;

export const Header = ({ children, ...rest }: Props): JSX.Element => (
  <Flex as="header" {...rest}>
    {children}
  </Flex>
);
